.JobSearch {
  @apply mb-36;

  .searchBox {
    @apply mb-10;
  }

  .topContent {
    @apply container mb-10;

    display: grid;
    grid-template-columns: 2fr auto;
    grid-template-rows: auto 1fr;
    grid-row-gap: 32px;
    grid-template-areas:
      "breadcrumbs breadcrumbs"
      "resultmessage resultmessage"
      "filterbutton bookmarks"
      "resultoptions resultoptions";

    @screen lg {
      grid-row-gap: 42px;
      grid-template-areas:
        "breadcrumbs bookmarks"
        "resultmessage resultoptions";
    }

    .filterButtonWrapper {
      grid-area: filterbutton;
      @apply block lg:hidden;
    }

    .breadcrumbsWrapper {
      grid-area: breadcrumbs;
    }

    .bookmarkButtonWrapper {
      grid-area: bookmarks;

      @apply w-full;
      @apply flex w-full justify-end;
    }

    .searchResultMessage {
      grid-area: resultmessage;

      @apply h-fit w-full lg:mb-0 lg:w-2/3;
      @apply font-headline text-3xl text-neutral-700;

      span {
        @apply text-toom-red-500;
      }
    }

    .resultOptions {
      grid-area: resultoptions;

      @apply flex w-full items-center justify-self-end;
      @apply gap-x-10 lg:w-fit lg:justify-end;

      .sortWrapper {
        @apply flex items-center gap-2;

        .sortTrigger {
          @apply flex items-center gap-2;
          @apply font-bold text-neutral-700;
          @apply outline-0 focus:outline-0;
        }

        .sortViewport {
          @apply rounded-sm border border-neutral-300 bg-white p-2;
          @apply outline-0 focus:outline-0;
        }

        .sortItem {
          @apply mb-3 cursor-pointer outline-0 last-of-type:mb-0 hover:text-toom-red-500 focus:outline-0;
        }
      }

      .displayType {
        @apply hidden items-center lg:flex;

        .toggleButton {
          @apply border border-neutral-500 p-2;

          &:first-of-type {
            @apply rounded-bl-[4px] rounded-tl-[4px] border-r-0;
          }

          &:last-of-type {
            @apply rounded-br-[4px] rounded-tr-[4px];
          }

          svg {
            @apply stroke-neutral-500;
          }

          &[data-state="on"] {
            @apply border-toom-red-500 bg-toom-red-500 text-white;
            svg {
              @apply stroke-white;
            }
          }
        }
      }
    }
  }

  .mainContent {
    @apply container w-full;
    @apply grid grid-cols-1 gap-6 lg:grid-cols-3;

    .googleMap {
      @apply hidden lg:block;
      @apply relative mb-10 aspect-video h-auto w-full;

      .googleMapButton {
        @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform;
        @apply z-50;
      }
    }

    .filterList {
      @apply col-span-1 hidden lg:block;
    }

    .resultList {
      @apply col-span-2;
      @apply flex flex-col gap-6;
    }
  }
}
