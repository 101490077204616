.SearchBox {
  @apply relative z-50;
  @apply rounded-sm bg-toom-red-500 text-white;
  @apply flex flex-col items-center px-6 py-8 md:px-20 md:py-16;

  @apply mx-auto w-4/5;
  &.fullWidth,
  &.mapMode {
    @apply w-full;
  }

  &.mapMode {
    @apply p-8;
  }

  &.newsletter {
    @apply m-0 inline-block bg-transparent p-0;

    .inputs {
      @apply w-full max-w-full p-0;

      .row {
        @apply w-full max-w-full p-0;

        @apply grid items-end gap-6;
        grid-template-areas:
          "term"
          "location";

        @screen lg {
          @apply gap-7;
          grid-template-columns: 1fr 1fr;
          grid-template-areas: "term location";
        }
      }
    }
  }

  h1 {
    @apply mb-6 text-center font-headline text-3xl font-bold md:text-4xl lg:text-5xl;
  }

  .jobCount {
    @apply absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2;
    @apply rounded-sm bg-toom-green-500 px-4 py-2;

    span {
      @apply text-sm font-bold md:text-xl;
    }
  }

  .inputs {
    @apply w-full lg:container;

    .row {
      @apply @container;

      @apply grid items-end gap-6;
      grid-template-areas:
        "term"
        "location"
        "filter"
        "action";

      @screen lg {
        grid-template-columns: 1fr 1fr max-content;
        grid-template-areas:
          "term location action"
          "filter filter filter";
      }

      .termWrapper {
        grid-area: term;
      }

      .locationWrapper {
        grid-area: location;
        flex: 1 1 100%;
        @apply w-full;
      }

      .filterWrapper {
        grid-area: filter;
        @apply flex items-center gap-6;
      }

      .actionWrapper {
        grid-area: action;
        @apply flex items-center justify-center;
      }
    }
  }

  &.mapMode {
    @apply bg-neutral-200 text-neutral-700;

    .inputs {
      @apply w-full;

      .row {
        @apply w-full;

        grid-template-columns: auto;
        grid-template-areas:
          "term"
          "location"
          "action";
      }
    }

    .actionWrapper {
      display: unset;
      @apply flex justify-center lg:flex;
    }
  }
}
