.SingleQuote {
  @apply container bg-neutral-100;

  .inner {
    @apply flex flex-wrap gap-10 px-0 lg:flex-nowrap lg:gap-20 lg:px-14;

    .image {
      @apply relative aspect-square h-48;
    }

    .content {
      .quote {
        @apply mb-6 text-xl;
      }

      .name {
        @apply mb-1 font-bold;
      }

      .position {
      }
    }
  }
}
