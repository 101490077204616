.Benefits {
  @apply my-[60px];

  svg * {
    @apply stroke-neutral-700 stroke-2;
  }

  &.green {
    @apply bg-toom-green text-white;

    svg * {
      @apply stroke-white;
    }
  }

  &.greyLight {
    @apply bg-neutral-100 text-black;

    svg * {
      @apply fill-toom-green stroke-toom-green;
    }
  }

  &.greyDark {
    @apply bg-neutral-700 text-white;

    svg * {
      @apply fill-white stroke-white;
    }
  }

  .inner {
    @apply container;

    .textContent {
      @apply mx-auto mb-8 max-w-[75%] text-start md:text-center;
    }

    .buttonWrapper {
      @apply flex w-full justify-center;
    }

    .benefitsGrid {
      @apply flex w-full flex-wrap justify-center;

      .benefit {
        @apply flex flex-col items-center;

        --mx: 24px;
        --my: 40px;
        margin: calc(var(--my) / 2) calc(var(--mx) / 2);
        @screen md {
          margin: var(--my) var(--mx);
        }

        width: calc(100% - var(--mx));
        @screen md {
          width: calc(50% - 2 * var(--mx));
        }
        @screen lg {
          width: calc(25% - 2 * var(--mx));
        }

        .benefitImage {
          width: auto;
          max-width: 100%;
          height: 100%;
          max-height: 60px;
          margin-bottom: 16px;
        }

        .headline {
          @apply mb-4 text-center text-xl font-bold;
          @apply md:min-h-[60px];
        }

        .copy {
          @apply text-center;

          ul {
            @apply list-disc;
          }
        }
      }
    }
  }
}
