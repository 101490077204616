.MapModal {
  @apply hidden md:block;

  .overlay {
    @apply fixed inset-0 h-screen max-h-screen w-screen overflow-hidden bg-neutral;
  }

  .content {
    @apply fixed overflow-y-auto overflow-x-hidden;
    @apply rounded-sm bg-white shadow-card;
    @apply bottom-0 left-0 right-0 top-[72px] z-[980] w-screen;

    @apply flex flex-nowrap;

    aside {
      flex: 0 0 40%;
      min-width: 400px;
      max-width: 500px;
      @apply shadow-sidemenu;
      @apply flex flex-col;
    }

    main {
      flex: 1 1 100%;
    }
  }

  .close {
    @apply absolute right-8 top-8 z-10;
  }
}
