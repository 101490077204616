.LocationAutocompleteInput {
  @apply relative flex flex-col;

  * {
    @apply focus:outline-none;
  }

  .Label {
    @apply w-full text-xs;
  }

  .InputWrapper {
    @apply relative w-full;

    .clearButton,
    .loadingIndicator {
      @apply absolute right-0 top-1/2 z-10 -translate-y-[50%] transform hover:cursor-pointer;

      svg {
        @apply stroke-black hover:stroke-gray-600;
      }
    }

    .loadingIndicator {
      @apply mr-4;
    }
  }

  .Input {
    @apply min-h-[24px] w-full break-keep rounded-sm py-2 pl-4 pr-8;

    @apply bg-white text-black;
    font-size: 16px;

    @apply outline outline-0 outline-neutral-400;

    &:focus,
    &:active {
      @apply outline-2 outline-neutral-600;
    }

    &[data-disabled] {
      @apply border-neutral-300 bg-neutral-300;
    }

    &[data-invalid] {
      @apply text-error-600 outline-2 outline-neutral-600;
    }

    &[data-headlessui-state="open"] {
      @apply rounded-bl-none rounded-br-none;

      ~ .rangeTrigger {
        @apply rounded-bl-none rounded-br-none;
      }
    }
  }

  .Options {
    @apply absolute left-0 top-full w-full;
    @apply bg-white p-4;
    @apply border-b border-l border-r border-neutral-400;
    @apply rounded-bl-sm rounded-br-sm;
    z-index: 9999;
  }

  .separator {
    @apply mb-5 mt-2 h-[1px] w-full bg-neutral-600;
  }

  .Item {
    @apply mb-1 px-3 py-2;
    @apply text-black;
    @apply cursor-pointer select-none;
    @apply flex items-center gap-2;

    &.active,
    &:hover {
      @apply bg-toom-red-50 text-toom-red-500;
    }
  }

  &.showRangeSelector {
    @apply flex flex-wrap items-end gap-2 md:flex-nowrap md:gap-0;

    .location {
      @apply w-full flex-shrink flex-grow;

      input {
        @apply md:rounded-br-none md:rounded-tr-none;
      }
    }
  }

  .rangeTrigger {
    @apply w-full sm:w-fit;
    @apply flex-shrink-0 flex-grow-0 flex-nowrap justify-between sm:justify-end;

    @apply flex min-h-[24px] items-center gap-2 px-4 py-2;
    @apply rounded-sm bg-white sm:rounded-bl-none sm:rounded-tl-none;
    @apply font-bold text-neutral-700;
    @apply outline-0 focus:outline-0;
  }

  .rangeViewport {
    @apply w-full rounded-sm border border-neutral-300 bg-white p-2 text-neutral-700;
    @apply outline-0 focus:outline-0;
  }

  .rangeItem {
    @apply mb-3 cursor-pointer outline-0 last-of-type:mb-0 hover:text-toom-red-500 focus:outline-0;
  }

  &.showRangeSelector {
    .Input {
      @apply rounded-br-none rounded-tr-none;
    }
  }
}
