.Pagination {
  @apply flex items-center justify-center;

  .link {
    @apply min-w-[36px] cursor-pointer rounded-sm bg-white p-2;
    @apply flex justify-center text-sm;

    @apply bg-white text-neutral-700;
    &:hover {
      @apply text-neutral-800;
    }

    &:active {
      @apply text-black;
    }

    &:focus {
      @apply outline-2 outline-neutral-500;
    }

    &:disabled {
      @apply text-neutral-300;
    }

    &.active {
      @apply bg-toom-red-500 text-white;

      &:hover {
        @apply bg-toom-red-600;
      }

      &:active {
        @apply bg-toom-red-700;
      }

      &:focus {
        @apply outline-2 outline-toom-red-200;
      }

      &:disabled {
        @apply bg-toom-red-50 text-toom-red-200;
      }
    }

    &[aria-disabled="true"] {
      @apply text-neutral-300;
    }
  }
}
