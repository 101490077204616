.JobNewsletterSignup {
  .box {
    @apply bg-toom-red-500 text-white;
    @apply relative mx-auto max-w-[90%];
    @apply px-6 pb-7 pt-7 lg:mb-[60px] lg:mt-[120px] lg:px-14 lg:py-8;
    @apply sm:pb-[250px] md:pb-[200px] lg:pb-[30px];

    &.submitted {
      @apply pb-[60px];
    }

    @apply flex flex-col-reverse lg:flex-row;

    .image {
      @apply absolute bottom-0 right-0 hidden sm:block;

      @screen lg {
        @apply absolute bottom-0 left-0;
      }

      img {
        @apply h-[180px] w-auto lg:h-auto lg:w-[300px];
      }
    }

    .form {
      @apply lg:pl-[250px];

      .title {
        @apply mb-5 text-center font-headline text-xl md:text-4xl lg:mb-2 lg:text-left;
      }

      .searchBox {
        @apply mb-6 w-full lg:mb-7;
      }

      .privacyNotice {
        @apply text-sm lg:text-xs;
        @apply relative mt-4 sm:absolute sm:bottom-7 sm:left-6 sm:mt-0 sm:w-1/2 lg:static lg:w-full;
        @apply lg:mt-7;
      }

      .inputs {
        @apply w-full;

        .row {
          @apply @container;

          @apply grid items-end gap-6;
          grid-template-areas:
            "term"
            "location"
            "email"
            "submit";

          @screen lg {
            grid-template-columns: repeat(1fr);
            grid-template-areas:
              "term location"
              "email submit";
          }

          .termWrapper {
            grid-area: term;
          }

          .locationWrapper {
            grid-area: location;
            flex: 1 1 100%;
            @apply w-full;
          }

          .email {
            grid-area: email;
          }

          .submit {
            grid-area: submit;
          }
        }
      }
    }

    .submission {
      @apply flex w-full flex-col justify-center gap-6 text-center;

      .title {
        @apply font-headline text-4xl;
      }

      .text {
        @apply text-xl;
      }

      .button {
        @apply mt-6 w-fit self-center;
      }
    }
  }
}
