@import "../../styles/variables";
@import "../../styles/typo";

.Accordion {
  @apply container;

  .inner {
    .textContent {
      @apply mb-8;

      .headline {
        @apply justify-center text-center;
      }

      .subline {
        @apply justify-center text-center;
        @include headline(2xl);
      }

      .topline {
        @apply justify-center text-center;
        @include headline(2xl);
      }
    }

    .accordion {
      @apply w-full lg:mx-auto lg:max-w-[80ch];

      .trigger {
        @apply text-xl font-bold;
        @apply border-b border-neutral-600 py-4;
        @apply flex w-full items-start justify-between text-left;

        svg {
          @apply ml-2 w-8 flex-shrink-0 stroke-toom-red-500 stroke-2;
          @apply transition-transform duration-300;
        }

        &[data-state="open"] {
          @apply border-b-0;

          svg {
            @apply rotate-180 transform;
          }
        }
      }

      .content {
        @apply py-4;

        &[data-state="open"] {
          @apply border-b border-neutral-600 py-4;
        }

        a {
          @apply text-toom-red-500 underline underline-offset-2 hover:text-toom-red-800;
        }
      }
    }
  }
}
