@mixin topline($size) {
  @apply md:text-#{$size} text-base leading-[1.3];
}

@mixin headline($size) {
  @apply md:text-#{$size} font-headline text-2xl leading-[1.3];
}

@mixin text-headline($size) {
  @apply md:text-#{$size} text-base leading-[1.3];
}

@mixin subline($size) {
  @apply md:text-#{$size} text-base leading-[1.3];
}

@mixin copy($size) {
  @apply md:text-#{$size} text-base leading-[1.5];
}
