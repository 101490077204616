.Card {
  @apply relative bg-white;
  @apply border border-neutral-200;

  .image {
    @apply relative w-full;

    img {
      @apply h-full w-full;
    }
  }

  .contentWrapper {
    @apply flex flex-col justify-between;
    @apply p-6;
  }
}
