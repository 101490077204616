.InputFieldWrapper {
  @apply relative w-full;

  .InputField {
    @apply flex items-baseline justify-between;
    @apply w-full min-w-[250px];

    .label {
      @apply text-xs;
    }
  }

  .InputWrapper {
    .Input {
      @apply min-h-[24px] w-full break-keep rounded-sm py-2 pl-4 pr-12;

      @apply bg-white text-black;
      font-size: 16px;

      @apply outline outline-0 outline-neutral-400;

      &:focus,
      &:active {
        @apply outline-2 outline-neutral-600;
      }

      &[data-disabled] {
        @apply border-neutral-300 bg-neutral-300;
      }

      &[data-invalid] {
        @apply text-error-600 outline-2 outline-neutral-600;
      }
    }

    .clearButton {
      @apply absolute right-4 top-1/2 z-10 -translate-y-[2px] transform hover:cursor-pointer;

      svg {
        @apply stroke-black hover:stroke-gray-600;
      }
    }
  }

  .errorMessageWrapper {
    @apply absolute -bottom-5 left-0 text-xs text-neutral-200;
  }
}
