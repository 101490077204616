.YoutubeEmbed {
  @apply h-full w-full;

  &.noConsent {
    @container;
    @apply h-full w-full;
    @apply bg-neutral-100 p-6;

    @apply flex flex-col items-center justify-start @md:justify-center;

    .title {
      @apply mb-4 font-headline text-2xl;
      @apply flex flex-wrap items-start justify-start gap-4 @md:flex-nowrap @md:justify-center;
    }

    .description {
      @apply text-left text-sm @md:text-center;
    }

    .actions {
      @apply mt-6 flex items-center justify-start gap-4 @md:justify-center;
    }

    .ytLogo {
      height: 30px;
      width: auto;
    }
  }

  &.consent {
  }
}
