.section {
  @apply flex flex-row;

  &.align-left {
    @apply justify-start;
  }

  &.align-center {
    @apply justify-center text-center;
  }

  &.align-right {
    @apply justify-end;
  }

  &.size-50 {
    .textContent {
      @apply w-full lg:w-1/2;
    }
  }

  &.size-75 {
    .textContent {
      @apply w-full lg:w-3/4;
    }
  }

  &.size-100 {
    .textContent {
      @apply w-full;
    }
  }

  .textContent {
    @apply relative;
  }
}
