.Slider {
  @apply relative h-full w-full;

  .swiperButtonNext,
  .swiperButtonPrev {
    @apply absolute top-24 z-50 cursor-pointer;
    @apply hidden aspect-square w-6 md:block;
  }

  svg {
    @apply stroke-toom-red-500;
  }

  .swiperButtonNext {
    @apply -right-24;

    transition: 0.1s ease-in-out;
    &:hover {
      transform: translateX(4px) scale(1.1);
    }
  }

  .swiperButtonPrev {
    @apply -left-24;

    transition: 0.1s ease-in-out;
    &:hover {
      transform: translateX(-4px) scale(1.1);
    }
  }
}
