.FilterList {
  @apply w-full bg-gray-50 px-6 py-5;

  .section {
    &:not(:first-of-type) {
      @apply mt-8;
    }

    .accordionTrigger {
      @apply flex w-full items-center justify-between;

      svg {
        @apply transition-transform duration-300 ease-in-out will-change-transform;
      }

      &[data-state="open"] {
        svg {
          @apply rotate-180;
        }
      }
    }
  }

  .activeFilterList {
    @apply mb-10;

    ul {
      @apply mb-3.5 flex flex-wrap items-center gap-2;
    }
  }
}
