.GoogleMap {
  @apply relative h-full w-full;

  .searchHereWrapper {
    @apply absolute left-1/2 top-10 z-10 -translate-x-1/2;
  }

  .refreshOptionWrapper {
    @apply absolute bottom-10 left-1/2 z-10 -translate-x-1/2;
    @apply rounded-sm bg-white px-4 py-2;
    @apply text-base font-bold;
  }

  .clusterMarker {
    @apply flex cursor-copy flex-nowrap items-center justify-center;
    @apply rounded-full bg-toom-red p-8;
    @apply text-2xl text-white;

    .clusterMarkerText {
      @apply flex flex-nowrap items-center justify-center break-keep;
    }
  }
}
