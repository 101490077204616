.overlay {
  @apply fixed inset-0 w-screen overflow-hidden bg-neutral;
}

.content {
  @apply fixed overflow-y-auto overflow-x-hidden;
  @apply rounded-sm bg-white shadow-card;
  @apply bottom-0 left-0 right-0 top-[56px];
  @apply z-[999] m-auto max-h-full md:top-[72px] lg:max-h-[80vh];
  @apply w-full max-w-full lg:max-w-[75vw];

  .closeWrapper {
    @apply w-full px-6 py-1.5 lg:py-6;
    @apply flex justify-end;
    @apply shadow-submenu lg:shadow-none;
  }

  .FilterList {
    @apply flex flex-col gap-10;

    .accordion {
      @apply flex flex-wrap gap-8;

      .section {
        @apply mt-0;
      }
    }
  }
}
