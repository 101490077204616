.section {
  @apply container my-[120px];

  .facts {
    @apply relative w-full;
    @apply grid grid-cols-1 gap-0 md:grid-cols-2 lg:grid-cols-4;

    .fact {
      @apply relative flex flex-col items-center justify-center text-center;

      &.noBg {
        @apply min-h-[120px];
      }

      .imageWrapper {
        @apply relative aspect-[3/2] w-full;

        svg {
          @apply h-full w-full stroke-1;
        }
      }

      .content {
        @apply absolute top-1/2 w-full -translate-y-1/2 transform;

        .number {
          @apply text-3xl font-bold md:text-5xl;

          &.red {
            @apply text-toom-red-500;
          }

          &.white {
            @apply text-white;
          }
        }

        .text {
          &.black {
            @apply text-neutral-700;
          }

          &.white {
            @apply text-white;
          }

          @apply text-lg font-normal;
        }
      }
    }
  }
}
