@import "../../styles/variables";
@import "../../styles/typo";

.TextContent {
  .topline {
    @apply mb-4 font-normal leading-[1.5] text-toom-red-500;

    @include topline(base);
    @each $fontSize in $fontSizes {
      &.size-#{$fontSize} {
        @include topline($fontSize);
      }
    }
  }

  .headline {
    @apply relative mb-4;

    @include headline(4xl);
    @each $fontSize in $fontSizes {
      &.size-#{$fontSize} {
        @include headline($fontSize);
      }
    }
  }

  .subline {
    @apply mb-4 font-bold leading-[1.5];

    @include subline(xl);
    @each $fontSize in $fontSizes {
      &.size-#{$fontSize} {
        @include subline($fontSize);
      }
    }
  }

  .copy {
    column-count: 1;
    column-gap: 24px;

    ul,
    li,
    ol {
      @apply break-inside-avoid;
    }

    @screen md {
      &.columns-1 {
        column-count: 1;
      }
      &.columns-2 {
        column-count: 2;
      }
      &.columns-3 {
        column-count: 3;
      }
    }

    @include copy(base);
    @each $fontSize in $fontSizes {
      &.size-#{$fontSize} {
        @include copy($fontSize);
      }
    }

    .copyHeadline {
      @include text-headline(xl);
      @apply font-bold;
      @each $fontSize in $fontSizes {
        &.headline-size-#{$fontSize} {
          @include text-headline($fontSize);
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply mb-2 mt-4;
      @apply break-inside-avoid break-after-avoid;
    }

    ol,
    ul {
      @apply mt-8;

      li {
        @apply mb-4 flex items-start gap-4 text-base;

        p {
          margin-top: -8px;
        }

        &::before {
          @apply h-[17px] w-[24px] flex-shrink-0 bg-toom-red;
          mask-image: url("/images/icons/check.svg");
          mask-size: contain;
          mask-repeat: no-repeat;
          content: " ";
        }
      }
    }

    a {
      @apply text-toom-red-500 underline underline-offset-2 hover:text-toom-red-800;
    }

    p:not(li p) {
      @apply mb-8;
    }

    li p {
      @apply mb-1;
    }
  }

  .buttons {
    @apply mt-8 flex flex-wrap items-center gap-4;
  }
}
