.ContactPersons {
  @apply container relative;

  .textContent {
    @apply mb-8 w-full xl:w-[75%];
  }

  .contactGroups {
    @apply flex flex-col gap-20;

    .contactGroup {
      .groupTitle {
        @apply mb-6 font-headline text-3xl;
      }
    }
  }

  .persons {
    @apply grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4;

    .person {
      @apply flex flex-col justify-center text-center;

      .title {
        @apply text-xl font-bold text-neutral-700;
      }

      .position {
        @apply text-base font-normal text-neutral-600;
      }

      .phone {
        @apply mt-3  text-toom-red-500;

        a {
          @apply flex items-center justify-center gap-4;
        }

        svg {
          @apply stroke-toom-red-500;
        }
      }
    }
  }
}
