.RecommendedJobsSlider {
  @apply bg-neutral-300;

  .inner {
    @apply container;

    .textContent {
      @apply mb-14;
    }

    .swiperContainer {
      @apply relative;

      .swiper {
        @apply h-full min-h-[310px] w-full;

        :global(.swiper-wrapper) {
          @apply h-full;
        }
      }

      .swiperNavNext,
      .swiperNavPrev {
        @apply absolute top-1/2 z-50 hidden cursor-pointer md:block;
        svg {
          @apply aspect-square h-12 w-12 stroke-toom-red-500;
        }
      }

      .swiperNavNext {
        @apply -right-24;

        transition: 0.1s ease-in-out;
        &:hover {
          transform: translateX(4px) scale(1.1);
        }
      }

      .swiperNavPrev {
        @apply -left-24;

        transition: 0.1s ease-in-out;
        &:hover {
          transform: translateX(-4px) scale(1.1);
        }
      }

      .swiperPagination {
        @apply flex md:hidden;
        @apply mt-4 h-12 w-full items-center justify-center;

        .bullet {
          @apply aspect-square h-4 w-4 rounded-sm bg-neutral-400 opacity-100;

          &.bulletActive {
            @apply bg-toom-red-500;
          }
        }
      }
    }

    .buttonWrapper {
      @apply mt-12 flex w-full justify-center;
    }
  }
}
