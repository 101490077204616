.section {
  @apply flex flex-col;

  .columns {
    @apply mt-8;
    @apply flex flex-row flex-wrap md:flex-nowrap;
    @apply gap-8;

    .column {
      &.size-40 {
        @apply w-full lg:w-[40%];
      }

      &.size-50 {
        @apply w-full lg:w-[50%];
      }

      &.size-60 {
        @apply w-full lg:w-[60%];
      }
    }
  }

  &.align-left {
    @apply justify-start;
  }

  &.align-center {
    @apply justify-center text-center;
  }

  &.align-right {
    @apply justify-end;
  }

  .textContent {
    @apply relative;
  }
}
