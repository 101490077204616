.Hero {
  @apply w-full p-0;

  &.homeHero {
    .heroInner {
      @apply relative h-full max-h-[950px];
    }

    .searchBox {
      @apply relative w-full max-w-full;

      @screen lg {
        @apply mx-auto max-w-[1000px] -translate-y-1/2 transform;
      }
    }

    .backgroundImageContainer {
      @apply relative h-full w-full;
      @apply h-[230px] md:h-[330px] lg:h-[770px];

      .swiper {
        @apply -z-10 h-full w-full;
      }

      img {
        @apply w-full;
      }

      :global(.swiper-wrapper) {
        @apply h-full;
      }
    }
  }

  &.pageHero {
    @apply bg-neutral-100;

    .heroInner {
      @apply relative flex flex-col lg:container lg:items-end lg:py-16;

      .backgroundImageContainer {
        @apply relative h-full w-full lg:w-3/4;
        @apply h-[230px] md:h-[330px] lg:h-[570px];

        .swiper {
          @apply z-0 h-full w-full;
        }

        img {
          @apply w-full;
        }

        :global(.swiper-wrapper) {
          @apply h-full;
        }

        :global(.swiper-slide) {
          transition-duration: 1600ms !important;
        }
      }

      .textContainer {
        @apply flex flex-col-reverse lg:flex-col;
        @apply container relative -mt-10 w-full md:-mt-14 lg:absolute lg:left-0 lg:top-1/2 lg:-mt-18 lg:mt-0;

        .topline {
          @apply mb-4 text-base text-toom-red-500;
          @apply max-w-[275px] lg:max-w-[23%];
        }

        .headline {
          @apply z-10 mb-4 max-w-[600px] lg:mb-0;

          span,
          h1 {
            @apply inline bg-toom-red-500 p-3;
            @apply font-headline text-3xl text-white md:text-5xl;
            @apply border-x-8 border-y-4 border-toom-red-500;

            // 66% support. Safari and Firefox missing.
            // Default fallback to word-break: break-word.
            word-break: auto-phrase;

            line-height: 1.5;

            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
          }
        }
      }
    }
  }
}
