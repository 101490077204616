.section {
  @apply bg-neutral-100;

  .inner {
    @apply container;

    .textContent {
      @apply mb-12;
    }

    .slideTextContentHeadline {
      @apply font-headline text-2xl;
    }

    .slideTextContentTopline {
    }

    .slideTextContentCopy {
    }

    .slideTextContentSubline {
    }
  }
}
