.Breadcrumbs {
  @apply flex flex-wrap items-center gap-1.5;

  .part {
    @apply flex items-center gap-1.5;

    &.last {
      @apply text-toom-red;
    }
  }
}
