@mixin proportions($width) {
  $textWidth: $width;
  $mediaWidth: 100 - $width;

  .wrapper {
    .textContent {
      @screen lg {
        width: #{$textWidth} + "%";
      }
    }

    .media {
      @screen lg {
        width: #{$mediaWidth} + "%";
      }
    }
  }
}

.section {
  &.reverse {
    .wrapper {
      @apply lg:flex-row-reverse;
    }
  }

  @for $i from 40 through 60 {
    @if $i % 10 == 0 {
      &.proportions-#{$i} {
        @include proportions($i);
      }
    }
  }

  .wrapper {
    @apply flex flex-col-reverse flex-wrap gap-y-7 lg:flex-row lg:flex-nowrap lg:gap-x-14 lg:gap-y-0;

    .textContent {
      @apply relative w-full;

      audio {
        @apply mt-8 min-w-[375px];

        &::-webkit-media-controls-enclosure {
          @apply rounded-sm;
        }
      }
    }

    .media {
      @apply relative h-max w-full;

      .imageWrapper {
        @apply relative h-full w-full;
        @apply aspect-video max-w-full overflow-hidden;

        img {
          @apply h-full w-full;
        }
      }

      .imageCaption {
        @apply mt-4 text-xs;
      }

      iframe {
        @apply aspect-video w-full;
      }
    }
  }
}
